import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { HeaderWithLine } from 'components/header-with-line';
import { NonStretchedImage } from 'components/non-stretched-image';
import { graphql, useStaticQuery } from 'gatsby';

const SNotFoundPageView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 2rem;
`;

const SSubtitle = styled.h2`
    font-weight: normal;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center;
`;

const SImage = styled(NonStretchedImage)`
    width: 182px;
`;

export const NotFoundPageView = () => {
    const images = useStaticQuery(graphql`
        {
            errorSign: file(relativePath: { eq: "404/404-sign.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 182
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return (
        <SNotFoundPageView>
            <SImage childImageSharp={images.errorSign.childImageSharp} />
            <HeaderWithLine isH1={true}>
                <FormattedMessage id="ups" />
            </HeaderWithLine>
            <SSubtitle>
                <FormattedMessage id="lookingFor" />
            </SSubtitle>
            <Button component="gatsby-link" to="/">
                <FormattedMessage id="goBack" />
            </Button>
        </SNotFoundPageView>
    );
};
