import React from 'react';
import { NotFoundPageView } from 'views/404';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/404.json';
import Layout from 'layouts/standard';

const NotFoundPage = ({ location }) => {
    return (
        <Layout
            location={location}
            messages={messages}
            navbar={false}
            fullPage={true}
        >
            <NotFoundPageView />
        </Layout>
    );
};

export default NotFoundPage;

export const Head = () => <SEO tags={TAGS.PAGE_NOT_FOUND} />;
